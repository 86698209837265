<template>
    <div>
        <a-row :gutter="24" type="flex">
            <a-col :span="24" class="mb-24">
                <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0 }">
                    <template #title>
                        <a-row type="flex" align="middle">
                            <a-col :span="24" :md="12">
                                <h5 class="font-semibold m-0">{{ $t('table_title.Form_Records') }}</h5>
                            </a-col>
                        </a-row>
                    </template>
                    <template>
                        <v-data-table
                            :headers="tableColumns"
                            :items="formRecordList"
                            :page="page"
                            :no-data-text="$t('cms.no_data_available')"
                            :loading="isLoadingFormQuestion"
                            :loading-text="$t('cms.loading')"
                            class="elevation-1"
                            hide-default-footer
                        >
                            <template v-slot:[`item.title`]="{ item }">
                                <div class="my-2 line-clamp-2">
                                    <span>{{ item.form.title || '-' }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.created_at_unix_timestamp`]="{ item }">
                                <div class="my-2">
                                    <span>{{ getFormatDate(+item.created_at_unix_timestamp) }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.updated_at_unix_timestamp`]="{ item }">
                                <div class="my-2">
                                    <span>{{ getFormatDate(+item.updated_at_unix_timestamp) }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <div class="d-flex">
                                    <router-link
                                        :to="{
                                            path: `/form-record-detail/${item.form_id}`,
                                            query: { course_id: courseID, record_id: item.id },
                                        }"
                                    >
                                        <v-btn color="indigo" class="mr-2" dark small>
                                            <v-icon left> mdi mdi-format-list-bulleted-type </v-icon>
                                            {{ $t('cms.detail') }}
                                        </v-btn>
                                    </router-link>
                                </div>
                            </template>
                        </v-data-table>
                        <div class="text-center py-5">
                            <f-pagination
                                v-model="page"
                                :pageSize="formQuestionPagination.pageSize"
                                :totalItems="formQuestionPagination.totalItems"
                                :disabled="isLoadingFormQuestion"
                            />
                        </div>
                    </template>
                </a-card>
            </a-col>
        </a-row>
        <a-row :gutter="24" type="flex">
            <a-col :span="24" class="mb-24"></a-col>
        </a-row>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { mixinFormats } from '../../mixins';

export default {
    mixins: [mixinFormats],
    data() {
        return {
            // TABLE VARIABLES
            tableColumns: [
                { text: this.$t('form.title'), value: 'title', sortable: false, width: '300px' },
                {
                    text: this.$t('form.created_at'),
                    value: 'created_at_unix_timestamp',
                    sortable: false,
                    width: '200px',
                },
                {
                    text: this.$t('form.updated_at'),
                    value: 'updated_at_unix_timestamp',
                    sortable: false,
                    width: '200px',
                },
                { text: this.$t('cms.action'), value: 'actions', sortable: false, width: '210px' },
            ],
            page: 1,
            courseID: this.$route.query.course_id,
        };
    },

    computed: {
        ...mapState('formQuestion', ['isLoadingFormQuestion', 'formRecordList', 'formQuestionPagination']),
    },

    async mounted() {
        await this.getList({ page: this.page, field: this.selectedField, search: this.search });
    },

    watch: {
        page(val) {
            this.getList({ page: val, field: this.selectedField, search: this.search });
        },
    },

    methods: {
        ...mapActions('formQuestion', ['getFormRecords']),

        async getList() {
            const { user_id, form_id, course_id } = this.$route.query;
            await this.getFormRecords({ user_id, form_id, course_id });
        },
    },
};
</script>

<style lang="scss"></style>
