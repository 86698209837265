var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',{attrs:{"gutter":24,"type":"flex"}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":24}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ padding: 0 }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('a-row',{attrs:{"type":"flex","align":"middle"}},[_c('a-col',{attrs:{"span":24,"md":12}},[_c('h5',{staticClass:"font-semibold m-0"},[_vm._v(_vm._s(_vm.$t('table_title.Form_Records')))])])],1)]},proxy:true}])},[[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableColumns,"items":_vm.formRecordList,"page":_vm.page,"no-data-text":_vm.$t('cms.no_data_available'),"loading":_vm.isLoadingFormQuestion,"loading-text":_vm.$t('cms.loading'),"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2 line-clamp-2"},[_c('span',[_vm._v(_vm._s(item.form.title || '-'))])])]}},{key:"item.created_at_unix_timestamp",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2"},[_c('span',[_vm._v(_vm._s(_vm.getFormatDate(+item.created_at_unix_timestamp)))])])]}},{key:"item.updated_at_unix_timestamp",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-2"},[_c('span',[_vm._v(_vm._s(_vm.getFormatDate(+item.updated_at_unix_timestamp)))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('router-link',{attrs:{"to":{
                                        path: ("/form-record-detail/" + (item.form_id)),
                                        query: { course_id: _vm.courseID, record_id: item.id },
                                    }}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"indigo","dark":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi mdi-format-list-bulleted-type ")]),_vm._v(" "+_vm._s(_vm.$t('cms.detail'))+" ")],1)],1)],1)]}}],null,true)}),_c('div',{staticClass:"text-center py-5"},[_c('f-pagination',{attrs:{"pageSize":_vm.formQuestionPagination.pageSize,"totalItems":_vm.formQuestionPagination.totalItems,"disabled":_vm.isLoadingFormQuestion},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]],2)],1)],1),_c('a-row',{attrs:{"gutter":24,"type":"flex"}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":24}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }